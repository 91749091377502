import(/* webpackMode: "eager" */ "/vercel/path1/app/components/links/links.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path1/app/components/logo/logo-with-text.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path1/app/components/navigation/navigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path1/app/components/page-loading-top/page-loader-top.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path1/app/components/sliding-footer/sliding-footer.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path1/app/components/toast-container-wrapper/toast-container-wrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["createVaultActionToast"] */ "/vercel/path1/app/earn/[...vault]/components/vault-action-toast/vault-action-toast.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path1/app/globals.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path1/app/components/footer/footer.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path1/app/components/links/links.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path1/app/providers/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ColorSchemeScript"] */ "/vercel/path1/node_modules/@mantine/core/esm/core/MantineProvider/ColorSchemeScript/ColorSchemeScript.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/next/font/google/target.css?{\"path\":\"app/shared/fonts/Roboto.ts\",\"import\":\"Roboto\",\"arguments\":[{\"subsets\":[\"latin\",\"cyrillic\"],\"weight\":[\"300\",\"400\",\"500\",\"700\"],\"display\":\"block\"}],\"variableName\":\"robotoFont\"}");
;
import(/* webpackMode: "eager", webpackExports: ["toast","update","onChange","dismiss"] */ "/vercel/path1/node_modules/react-toastify/dist/react-toastify.esm.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/react-toastify/dist/ReactToastify.min.css");
